import styled from '@emotion/styled'
import { breakpoints } from 'src/utils/styles'

export const BigLogo = styled.img`
  width: 280px;
  vertical-align: middle;
  top: 5%;

  :hover {
    border: 0;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    width: 137px;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 137px;
  }

  &[data-active='true'] {
    @media (min-width: ${breakpoints.l}px) {
      display: none;
    }
  }
`
