import React from 'react'
import StoreContext from 'src/context/StoreContext'
import LineItem from './LineItem'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getPrice } from 'src/utils/price'
import loadingSVG from 'src/static/icons/svg/loading.svg'
import { IS_BLACK_FRIDAY } from 'src/utils/globals'
import {
  StHeadlineWrapper,
  StCloseButton,
  CartWrapper,
  StHeadline,
  StSubTotal,
  StShippingcostWrapper,
  StShippingcostHeadline,
  StShippingcostText,
  StLineItemWrapper,
  StPrice,
  StCheckoutButton,
  StProceedShoppingButton,
  StWrapper,
  StInnerGridWrapper,
  StButtonWrapper,
  StSubTotalText,
  StEmptyCart,
  StSmallText,
  StActionButtonWrapper,
  StyledLoadingSvg,
  StCloseButtonIcon,
  StCloseButtonIconWhite,
} from './styles'
import NewsletterInfo from '../NewsletterInfo'

const Cart = props => {
  const { setIsOpen } = props
  const { i18n } = useTranslation('shoppingCart')
  const { checkout, loading } = React.useContext(StoreContext)

  const handleCheckout = () => {
    const checkoutUrl = checkout.webUrl.replace(
      'lobster-and-lemonade-apparel.myshopify',
      'checkout.lobsterlemonade'
    )
    window.open(checkoutUrl, '_self')
  }

  const lineItems = checkout.lineItems.map(item => (
    <LineItem key={item.id.toString()} item={item} />
  ))

  const numberOfItemsInCart = checkout.lineItems.reduce(
    (accumulator, currentValue) => {
      return parseInt(accumulator) + parseInt(currentValue.quantity)
    },
    [0]
  )

  const actionButtons = (
    <StButtonWrapper>
      <StProceedShoppingButton onClick={() => setIsOpen(false)}>
        {i18n.t('proceed.shopping')}
      </StProceedShoppingButton>
      <StCheckoutButton onClick={handleCheckout} disabled={checkout.lineItems.length === 0}>
        {i18n.t('go.to.checkout')}
      </StCheckoutButton>
      <StSmallText>{i18n.t('exactCosts')}</StSmallText>
      <StSmallText> {i18n.t('coupons')}</StSmallText>
    </StButtonWrapper>
  )

  return (
    <CartWrapper>
      <StHeadlineWrapper>
        <StHeadline>
          {i18n.t('title')} ({numberOfItemsInCart})
        </StHeadline>
        <StCloseButton onClick={() => setIsOpen(false)}>
          {IS_BLACK_FRIDAY ? <StCloseButtonIconWhite /> : <StCloseButtonIcon />}
        </StCloseButton>
      </StHeadlineWrapper>
      {lineItems.length > 0 ? (
        <>
          <StLineItemWrapper>{lineItems}</StLineItemWrapper>
          <StWrapper>
            <StInnerGridWrapper>
              <StSubTotal>
                <StSubTotalText>{i18n.t('subtotal')}</StSubTotalText>
                <StPrice>{getPrice(checkout.subtotalPrice.amount, checkout)}</StPrice>
              </StSubTotal>
              <StShippingcostWrapper>
                <StShippingcostHeadline>{i18n.t('shippingCosts.title')}</StShippingcostHeadline>
                <StShippingcostText>{i18n.t('shippingCosts.freeShipping')}</StShippingcostText>
                <StShippingcostText>{i18n.t('shippingCosts.inEu')}</StShippingcostText>
                <StShippingcostText>{i18n.t('shippingCosts.outerCountries')}</StShippingcostText>
              </StShippingcostWrapper>
              {actionButtons}
              <div style={{ marginBottom: '1rem' }}>
                <NewsletterInfo />
              </div>
            </StInnerGridWrapper>
          </StWrapper>
        </>
      ) : (
        <>
          <StEmptyCart>
            {loading ? (
              <StyledLoadingSvg src={loadingSVG} className="ml-4" alt="loading" />
            ) : (
              i18n.t('emptyCart')
            )}
          </StEmptyCart>
          <StActionButtonWrapper>{actionButtons}</StActionButtonWrapper>
        </>
      )}
    </CartWrapper>
  )
}

Cart.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
}

export default Cart
