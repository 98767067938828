import styled from '@emotion/styled'
import { breakpoints } from 'src/utils/styles'

export const StCountdownContainer = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
`

export const StFlexContainer = styled.div`
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
`

export const StLetters = styled.span`
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 1pt;

  @media (max-width: ${breakpoints.xxl}px) {
    font-size: 1.25rem;
    letter-spacing: 0.25pt;
  }

  @media (max-width: ${breakpoints.xl}px) {
    font-size: 1.25rem;
    letter-spacing: 0.5pt;
  }

  @media (max-width: ${breakpoints.l}px) {
    font-size: 1rem;
    letter-spacing: 0.5pt;
  }

  @media (max-width: ${breakpoints.m}px) {
    font-size: 0.9rem;
    letter-spacing: 0.5pt;
  }

  @media (max-width: ${breakpoints.s}px) {
    font-size: 0.85rem;
    letter-spacing: 1pt;
  }

  @media (max-width: ${breakpoints.xs}px) {
    font-size: 0.75rem;
    letter-spacing: 1pt;
  }
`

export const StTime = styled.span`
  font-size: 3rem;

  @media (max-width: ${breakpoints.xxl}px) {
    font-size: 3rem;
  }

  @media (max-width: ${breakpoints.xl}px) {
    font-size: 1.75rem;
  }

  @media (max-width: ${breakpoints.l}px) {
    font-size: 1.5rem;
  }

  @media (max-width: ${breakpoints.m}px) {
    font-size: 1.4rem;
  }

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.25rem;
  }

  @media (max-width: ${breakpoints.xs}px) {
    font-size: 1rem;
  }
`

export const StDoubleDot = styled.span`
  font-size: 3rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  @media (max-width: ${breakpoints.xxl}px) {
    font-size: 3rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  @media (max-width: ${breakpoints.xl}px) {
    font-size: 1.8rem;
  }

  @media (max-width: ${breakpoints.l}px) {
    font-size: 1.5rem;
  }

  @media (max-width: ${breakpoints.m}px) {
    font-size: 1.4rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1rem;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }

  @media (max-width: ${breakpoints.xs}px) {
    font-size: 1rem;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }
`
