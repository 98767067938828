import React from 'react'
import PropTypes from 'prop-types'
import Cart from 'src/components/Cart'
import { StInnerWrapper, StOuterWrapper } from './styles'

const CartSlider = props => {
  const { isOpen, setIsOpen } = props
  return (
    <StOuterWrapper isOpen={isOpen}>
      <StInnerWrapper>
        <Cart setIsOpen={setIsOpen} />
      </StInnerWrapper>
    </StOuterWrapper>
  )
}

CartSlider.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
}

export default CartSlider
