import React from 'react'
import { Link } from 'gatsby-plugin-react-i18next'
import darkLogo from 'src/static/images/logo.svg'
import whiteLogo from 'src/static/images/logo_white.svg'
import { BigLogo } from './styles'
import { IS_BLACK_FRIDAY } from 'src/utils/globals'

const Logo = () => {
  return (
    <React.Fragment>
      <Link to="/">
        <BigLogo src={IS_BLACK_FRIDAY ? whiteLogo : darkLogo} alt="Lobster & Lemonade Logo" />
      </Link>
    </React.Fragment>
  )
}

export default Logo
