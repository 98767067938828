import React, { useState } from 'react'
import { Link } from 'gatsby'
import StoreContext from 'src/context/StoreContext'
import {
  Wrapper,
  NameWrapper,
  PriceWrapper,
  Price,
  RemoveButton,
  SelectedSize,
  QuantityWrapper,
  QuantityButton,
  Quantity,
  Title,
  SalePrice,
  SaleOldPrice,
  ErrorSpan,
  ImageThumbnail,
} from './styles'
import { getPrice } from 'src/utils/price'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'

const LineItem = props => {
  const { item } = props
  const { removeLineItem, checkout, updateLineItem } = React.useContext(StoreContext)
  const { i18n } = useTranslation('lineItem')
  const [quantityError, setQuantityError] = useState(false)

  const variantImage = item.variant.image ? (
    <ImageThumbnail src={item.variant.image.src} alt={`${item.title} product shot`} />
  ) : null

  const selectedOptions = item.variant.selectedOptions
    ? item.variant.selectedOptions.map(
        option =>
          `${i18n.t('lineItem.size')}: ${
            option.value === 'Default Title'
              ? get(
                  item.customAttributes.find(att => att.key === '_size'),
                  'value'
                )
              : option.value
          } `
      )
    : null

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id)
  }

  const inventoryQuantity =
    item.customAttributes.find(att => att.key === '_inventoryQuantity')?.value || 0

  const handleQuantity = quantity => {
    if (inventoryQuantity < quantity) {
      setQuantityError(true)
      return
    }
    if (quantity === 0) return
    updateLineItem(checkout.id, item.id, quantity)
    setQuantityError(false)
  }

  const checkIfCompareAtPriceExistsAndIsNotZero = () => {
    return !!(
      item.variant.compareAtPrice &&
      item.variant.compareAtPrice !== 0 &&
      item.variant.compareAtPrice !== null
    )
  }

  const price =
    item.variant.compareAtPrice != null &&
    checkIfCompareAtPriceExistsAndIsNotZero &&
    item.variant.price.amount < item.variant.compareAtPrice.amount ? (
      <PriceWrapper>
        <SalePrice>{getPrice(item.variant.price.amount, checkout)}</SalePrice>
        <SaleOldPrice>{getPrice(item.variant.compareAtPrice.amount, checkout)}</SaleOldPrice>
      </PriceWrapper>
    ) : (
      <PriceWrapper>
        <Price>{getPrice(item.variant.price.amount, checkout)}</Price>
      </PriceWrapper>
    )

  const articleName =
    item.customAttributes.find(att => att.key === '_productTitle')?.value || item.title

  return (
    <Wrapper>
      <Link
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        to={`/product/${item.variant.product.handle}/`}
      >
        {variantImage}
      </Link>
      <NameWrapper>
        <Title>{articleName}</Title>
        <SelectedSize>{selectedOptions}</SelectedSize>
        <RemoveButton onClick={handleRemove}>{i18n.t('remove')}</RemoveButton>
      </NameWrapper>
      <div>
        <QuantityWrapper>
          <QuantityButton
            disabled={parseInt(item.quantity) === 1}
            onClick={() => handleQuantity(item.quantity - 1)}
          >
            &ndash;
          </QuantityButton>
          <Quantity>{item.quantity}</Quantity>
          <QuantityButton onClick={() => handleQuantity(item.quantity + 1)}>+</QuantityButton>
        </QuantityWrapper>
        {quantityError && (
          <ErrorSpan>{i18n.t('out.of.stock', { quantity: inventoryQuantity })}</ErrorSpan>
        )}
      </div>

      {price}
    </Wrapper>
  )
}

export default LineItem
