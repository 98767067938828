import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import { IS_BLACK_FRIDAY } from 'src/utils/globals'
import {
  StCloseButton,
  StCloseButtonIcon,
  StCloseButtonIconWhite,
  StInnerInputWrapper,
  StMobileSearch,
  StSearchForm,
  StSearchFormLabel,
  StSearchIcon,
} from './styles'

export const SearchInputMobile = () => {
  const [search, setSearch] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const inputRef = useRef(null)

  const { t } = useTranslation('misc')

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleClickOutside = e => {
    if (
      !e.target.classList.contains('mobile-search-form') &&
      !e.target.classList.contains('mobile-search-input')
    ) {
      setIsOpen(false)
    }
  }

  return (
    <StSearchForm
      isOpen={isOpen}
      className={'mobile-search-form'}
      onSubmit={event => {
        event.preventDefault()
        navigate(`/search?value=${encodeURIComponent(search)}`)
        inputRef.current.blur()
      }}
    >
      <StSearchFormLabel>
        <StCloseButton
          style={{ zIndex: 1 }}
          onClick={e => {
            e.preventDefault()
            setIsOpen(true)
            inputRef && inputRef.current.focus()
          }}
        >
          <StSearchIcon isOpen={isOpen} />
        </StCloseButton>
        <StInnerInputWrapper isOpen={isOpen}>
          <StMobileSearch
            name="value"
            type="text"
            value={search}
            className={'mobile-search-input'}
            onChange={e => setSearch(e.target.value)}
            placeholder={t('search.placeholder')}
            ref={inputRef}
          />
          <StCloseButton onClick={() => setIsOpen(false)}>
            {IS_BLACK_FRIDAY ? <StCloseButtonIconWhite /> : <StCloseButtonIcon />}
          </StCloseButton>
        </StInnerInputWrapper>
      </StSearchFormLabel>
    </StSearchForm>
  )
}
