import React from 'react'
import { useCountdown } from 'src/hooks/useCountdown'
import { StCountdownContainer, StTime, StDoubleDot, StLetters, StFlexContainer } from './styles'

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate)

  const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <StCountdownContainer>
        <StFlexContainer>
          <div>
            <StTime>{days}</StTime>
            <br />
            <StLetters>{'Days'}</StLetters>
          </div>
          <StDoubleDot>:</StDoubleDot>
          <div>
            <StTime>{hours}</StTime>
            <br />
            <StLetters>{'Hours'}</StLetters>
          </div>
          <StDoubleDot>:</StDoubleDot>
          <div>
            <StTime>{minutes}</StTime>
            <br />
            <StLetters>{'Minutes'}</StLetters>
          </div>
          <StDoubleDot>:</StDoubleDot>
          <div>
            <StTime>{seconds}</StTime>
            <br />
            <StLetters>{'Seconds'}</StLetters>
          </div>
        </StFlexContainer>
      </StCountdownContainer>
    )
  }

  return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />
}

export default CountdownTimer
