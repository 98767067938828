import React from 'react'
import { SaleOldPrice, SalePrice } from 'src/components/ProductTiles/styles'

export const getPrice = (price, checkout, language = 'default') => {
  let currentLanguage
  if (language === 'default') {
    currentLanguage =
      typeof window !== 'undefined' && window.location.pathname.includes('/de/')
        ? 'de-DE'
        : typeof window !== 'undefined' && window.location.pathname.includes('/en/')
        ? 'en-US'
        : undefined
  } else {
    currentLanguage =
      language !== undefined && language === 'de'
        ? 'de-DE'
        : language !== undefined && language !== undefined && language === 'en'
        ? 'en-US'
        : undefined
  }

  return Intl.NumberFormat(currentLanguage, {
    currency: checkout.currencyCode ? checkout.currencyCode : 'EUR',
    minimumFractionDigits: 2,
    style: 'currency',
    localeMatcher: 'best fit',
  }).format(parseFloat(price ? price : 0))
}

export const getPriceWithSale = (variants, checkout, language = 'default', currentVariant) => {
  const variantToUse = currentVariant ? currentVariant : variants[0]
  if (variantToUse && variantToUse.price) {
    let isInSale
    let indexAtSale = 0
    isInSale = variants.some((element, index) => {
      if (
        parseFloat(element.compareAtPrice) !== 0 &&
        parseFloat(element.compareAtPrice) >= parseFloat(element.price)
      ) {
        indexAtSale = index
        return true
      } else return false
    })

    const saleVariantToUse = currentVariant ? currentVariant : variants[indexAtSale]

    if (!isInSale) {
      return getPrice(variantToUse.price, checkout, language)
    } else if (isInSale)
      return (
        <div>
          <SalePrice>{getPrice(saleVariantToUse.price, checkout, language)}</SalePrice>
          <SaleOldPrice>
            {getPrice(saleVariantToUse.compareAtPrice, checkout, language)}
          </SaleOldPrice>
        </div>
      )
  } else return null
}
