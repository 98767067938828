import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import StoreContext from 'src/context/StoreContext'
import Logo from './Logo'

import {
  Container,
  ShoppingBagIcon,
  Wrapper,
  BagQuantity,
  NavItemLeft,
  NavItemRight,
  NavIcons,
  WrapperMobile,
  ContainerMobile,
  MobileCartIconWrapper,
  ShoppingBagIconWhite,
} from './styles'
import { SearchInputMobile } from 'src/components/CustomInputs/SearchInputMobile'
import Searchbar from './Searchbar'
import CartSlider from 'src/components/CartSlider'
import useQueryParams from 'src/hooks/useQueryParams'
import { IS_BLACK_FRIDAY } from 'src/utils/globals'
import StickyHeader from '../StickyHeader'
import { breakpoints } from '../../utils/styles'
import styled from '@emotion/styled'

const StStickyHeader = styled(StickyHeader)`
  display: none;
  @media (max-width: ${breakpoints.l}px) {
    display: flex;
  }
`

const countQuantity = lineItems => {
  let quantity = 0

  lineItems.forEach(item => {
    quantity = quantity + item.quantity
  })
  return quantity
}

const Navigation = () => {
  const { checkout } = React.useContext(StoreContext)
  const [quantity, setQuantity] = useState(countQuantity(checkout ? checkout.lineItems : []))

  const [isCartOpen, setIsCartOpen] = useState(false)
  const [cartOpenFromQuery] = useQueryParams('cart', false)

  useEffect(() => {
    if (cartOpenFromQuery) {
      setIsCartOpen(true)
    }
  }, [cartOpenFromQuery])

  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []))
  }, [checkout])

  const whiteCartIcon = quantity => {
    return quantity > 0 ? (
      <>
        <BagQuantity>{quantity}</BagQuantity>
        <ShoppingBagIconWhite />
      </>
    ) : (
      <ShoppingBagIconWhite />
    )
  }
  const cartIcon = quantity => {
    return quantity > 0 ? (
      <>
        <BagQuantity>{quantity}</BagQuantity>
        <ShoppingBagIcon />
      </>
    ) : (
      <ShoppingBagIcon />
    )
  }
  return (
    <>
      <Wrapper>
        <Container>
          <Logo />
          <NavIcons>
            <NavItemLeft>
              <Searchbar />
            </NavItemLeft>
            <NavItemRight>
              <button
                style={{ all: 'unset', cursor: 'pointer' }}
                onClick={() => setIsCartOpen(!isCartOpen)}
              >
                {IS_BLACK_FRIDAY ? whiteCartIcon(quantity) : cartIcon(quantity)}
              </button>
            </NavItemRight>
          </NavIcons>
        </Container>
      </Wrapper>
      <CartSlider isOpen={isCartOpen} setIsOpen={setIsCartOpen} />

      <WrapperMobile>
        <ContainerMobile>
          <Logo />
          <SearchInputMobile />
          <MobileCartIconWrapper>
            <button
              style={{ all: 'unset', cursor: 'pointer', paddingTop: '2px' }}
              onClick={() => setIsCartOpen(!isCartOpen)}
            >
              {IS_BLACK_FRIDAY ? whiteCartIcon(quantity) : cartIcon(quantity)}
            </button>
          </MobileCartIconWrapper>
        </ContainerMobile>
      </WrapperMobile>
      <StStickyHeader isNFL={false} />
    </>
  )
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation
