import React from 'react'
import { fonts, fontSizes } from 'src/utils/styles'
import { I18nextContext, useI18next } from 'gatsby-plugin-react-i18next'
import { useTranslation } from 'react-i18next'
import { IS_BLACK_FRIDAY } from 'src/utils/globals'
import {
  StArrowIcon,
  StArrowIconWhite,
  StFooter,
  StFooterLinkPrimary,
  StFooterLinkPrimaryNewTarget,
  StFooterLinkPrimaryNoMarginBot,
  StFooterLinkSecondary,
  StFooterLinkSecondaryBlankTarget,
  StInstagram,
  StInstagramWhite,
  StLanguageContainer,
  StLanguageLink,
  StMiscContainer,
  StPrimaryContainer,
  StSecondaryContainer,
  StSocialMediaContainer,
  StWrapper,
  StYouTube,
  StYouTubeWhite,
} from './styles'

const Footer = () => {
  const { languages, originalPath } = useI18next()
  const context = React.useContext(I18nextContext)
  const currentLanguage = context.language
  const { i18n } = useTranslation('footer')

  const createFitGuideLink = () => {
    if (currentLanguage === 'en') {
      return 'https://lobsterlemonade-cloud.com/fit_guide/lobster-lemonade_fit-guide_EN.pdf'
    } else {
      return 'https://lobsterlemonade-cloud.com/fit_guide/lobster-lemonade_fit-guide_DE.pdf'
    }
  }

  const getLanguageLinks = () => {
    if (currentLanguage === 'en') {
      return (
        <>
          <StLanguageLink
            to={originalPath}
            language={languages[0]}
            style={{ fontFamily: 'LL-NeuePlakText-Bold' }}
          >
            {languages[0].toUpperCase()}
          </StLanguageLink>
          <span
            style={{
              fontFamily: fonts.regular,
              fontSize: fontSizes.footerText,
            }}
          >
            /
          </span>
          <StLanguageLink to={originalPath} language={languages[1]}>
            {languages[1].toUpperCase()}
          </StLanguageLink>
        </>
      )
    } else if (currentLanguage === 'de') {
      return (
        <>
          <StLanguageLink to={originalPath} language={languages[0]}>
            {languages[0].toUpperCase()}
          </StLanguageLink>
          <span
            style={{
              fontFamily: fonts.regular,
              fontSize: fontSizes.footerText,
            }}
          >
            /
          </span>
          <StLanguageLink
            to={originalPath}
            language={languages[1]}
            style={{ fontFamily: 'LL-NeuePlakText-Bold' }}
          >
            {languages[1].toUpperCase()}
          </StLanguageLink>
        </>
      )
    }
  }

  return (
    <StFooter>
      <StWrapper>
        <StSecondaryContainer>
          <StFooterLinkSecondaryBlankTarget
            href="https://about.lobsterlemonade.com/"
            target="_blank"
          >
            {i18n.t('footer.aboutUs')}
          </StFooterLinkSecondaryBlankTarget>
          <StFooterLinkSecondaryBlankTarget href={'https://fidubrands.com/#jobs'} target="_blank">
            {i18n.t('footer.jobs')}
          </StFooterLinkSecondaryBlankTarget>
          <StFooterLinkSecondary to="/imprint">{i18n.t('footer.imprint')}</StFooterLinkSecondary>
          <StFooterLinkSecondary to="/terms-and-conditions">
            {i18n.t('footer.termsAndCondition')}
          </StFooterLinkSecondary>
          <StFooterLinkSecondary to="/privacy">
            {i18n.t('footer.dataPrivacy')}
          </StFooterLinkSecondary>
        </StSecondaryContainer>
        <StPrimaryContainer>
          <StFooterLinkPrimary to="/newsletter">
            {i18n.t('footer.Newsletter')} {IS_BLACK_FRIDAY ? <StArrowIconWhite /> : <StArrowIcon />}
          </StFooterLinkPrimary>
          <StFooterLinkPrimary to="/returns">
            {i18n.t('footer.returns')}
            {IS_BLACK_FRIDAY ? <StArrowIconWhite /> : <StArrowIcon />}
          </StFooterLinkPrimary>
          <StFooterLinkPrimaryNewTarget
            href={createFitGuideLink()}
            target="_blank"
            rel="noopener noreferrer"
          >
            {i18n.t('footer.fitGuide')}
            {IS_BLACK_FRIDAY ? <StArrowIconWhite /> : <StArrowIcon />}
          </StFooterLinkPrimaryNewTarget>
          <StFooterLinkPrimaryNoMarginBot to="/helpdesk">
            {i18n.t('footer.contact')}
            {IS_BLACK_FRIDAY ? <StArrowIconWhite /> : <StArrowIcon />}
          </StFooterLinkPrimaryNoMarginBot>
          <StMiscContainer>
            <StLanguageContainer>{getLanguageLinks()}</StLanguageContainer>

            <StSocialMediaContainer>
              <a
                href="https://www.instagram.com/lobsterlemonade/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {IS_BLACK_FRIDAY ? <StInstagramWhite /> : <StInstagram />}
              </a>
              <a
                href="https://www.youtube.com/user/LobsterALemonade"
                target="_blank"
                rel="noopener noreferrer"
              >
                {IS_BLACK_FRIDAY ? <StYouTubeWhite /> : <StYouTube />}
              </a>
            </StSocialMediaContainer>
          </StMiscContainer>
        </StPrimaryContainer>
      </StWrapper>
    </StFooter>
  )
}

export default Footer
