import { useEffect, useState } from 'react'

const useCountdown = targetDate => {
  const countDownDate = new Date(targetDate).getTime()

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime())

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  return getReturnValues(countDown)
}

const addLeadingZero = time => {
  if (time < 10) {
    return '0' + time
  }
  return time
}

const getReturnValues = countDown => {
  let days = Math.floor(countDown / (1000 * 60 * 60 * 24))
  let hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  let minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  let seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  days = addLeadingZero(days)
  hours = addLeadingZero(hours)
  minutes = addLeadingZero(minutes)
  seconds = addLeadingZero(seconds)

  return [days, hours, minutes, seconds]
}

export { useCountdown }
