import React, { useRef, useState } from 'react'
import { SearchForm, Search, SearchLabelContainer, SearchIcon, SearchIconWhite } from './styles'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import { IS_BLACK_FRIDAY } from 'src/utils/globals'

export default function Searchbar() {
  const [search, setSearch] = useState('')
  const { i18n } = useTranslation('misc')
  const inputRef = useRef(null)

  const context = React.useContext(I18nextContext)
  const currentLanguage = context.language

  return (
    <SearchForm
      onSubmit={event => {
        event.preventDefault()
        navigate(`/${currentLanguage}/search?value=${encodeURIComponent(search)}`)
        inputRef.current.blur()
      }}
    >
      <SearchLabelContainer>
        <label htmlFor="searchbar">
          {IS_BLACK_FRIDAY ? <SearchIconWhite /> : <SearchIcon />}
          <Search
            enterKeyHint="search"
            id="searchbar"
            name="value"
            type="text"
            value={search}
            ref={inputRef}
            onChange={e => setSearch(e.target.value)}
            placeholder={i18n.t('search.placeholder')}
          />
        </label>
      </SearchLabelContainer>
    </SearchForm>
  )
}
