import styled from '@emotion/styled'
import { breakpoints, colors, fonts, Img, fontSizes } from 'src/utils/styles'
import { Link } from 'gatsby-plugin-react-i18next'
import { css } from '@emotion/react'

const ProductTagStyles = css`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  letter-spacing: 1pt;
  pointer-events: none;
  z-index: 2;
  line-height: 17px;
  padding: 0 0 0.1em 0;
  font-family: ${fonts.regular};
  font-size: ${fontSizes.productTagDefault};
  min-width: 75px;
  height: 23px;

  @media (max-width: ${breakpoints.m}px) {
    min-width: 65px;
    height: 20px;
    font-size: ${fontSizes.productTagMobile};
  }

  @media (max-width: ${breakpoints.s}px) {
    top: 12px;
    right: 12px;
    min-width: 50px;
    height: 20px;
    font-size: ${fontSizes.productTagMobile};
  }
`

export const ProductImg = styled(Img)`
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
`

export const ProductImgContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  z-index: 1;

  @media (min-width: ${breakpoints.l}px) {
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;

    transition-delay: 300ms;
    transition-property: opacity;

    :hover {
      opacity: ${props => (props.showTransition ? 0 : 1)};
    }
  }
`

export const ProductImgContainerFadeIn = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  overflow: hidden;
  display: flex;
`

export const ProductImagePlaceHolder = styled.div`
  width: 150px;
  height: 150px;
  background-color: ${colors.lobsterRed};
`

export const StLink = styled(Link)`
  position: relative;
  margin: auto;
  max-width: 360px;
  max-height: 450px;
  padding-top: 125%;
  width: 100%;
  height: 100%;

  @media (max-width: ${breakpoints.s}px) {
    height: auto;
  }
`
export const StLinkTwoColumns = styled(Link)`
  position: relative;
  margin: auto;

  max-width: 750px;
  max-height: 450px;
  padding-top: 60%;
  width: 100%;
  height: 100%;

  @media (max-width: ${breakpoints.s}px) {
    max-width: 335px;
    padding-top: 59.701492537%;
  }
`
export const StLinkTwoColumnsTwoRows = styled(Link)`
  position: relative;
  margin: auto;

  max-width: 750px;
  max-height: 1000px;
  padding-top: 133.333333333%;
  width: 100%;
  height: 100%;

  @media (max-width: ${breakpoints.s}px) {
    max-width: 336px;
    padding-top: 125%;
  }
`

export const ProductTag = styled.div`
  ${ProductTagStyles};
  top: 15px;
  right: 15px;
  font-size: ${fontSizes.productTagDefault};

  @media (max-width: ${breakpoints.s}px) {
    top: 12px;
    right: 12px;
  }
`

export const ProductTagDetailPage = styled.div`
  ${ProductTagStyles};
  top: 45px;
  right: 37px;

  @media (max-width: ${breakpoints.s}px) {
    top: 20px;
    right: 20px;
  }
`

export const ForSaleTag = styled(ProductTag)`
  border: 1px solid ${colors.lobsterRed};
  color: ${colors.lobsterRed};
`
export const SoldOutTag = styled(ProductTag)`
  border: 1px solid ${colors.darkGrayNoInverter};
  padding: 0 0.5em;
  color: ${colors.darkGrayNoInverter};
`
export const PreOrderTag = styled(ProductTag)`
  border: 1px solid ${colors.darkGrayNoInverter};
  padding: 0 0.5em 0.1em;
  color: ${colors.darkGrayNoInverter};
`
export const NewTag = styled(ProductTag)`
  border: 1px solid ${colors.darkGrayNoInverter};
  color: ${colors.darkGrayNoInverter};
`

export const ForSaleDetailPageTag = styled(ProductTagDetailPage)`
  border: 1px solid ${colors.lobsterRed};
  color: ${colors.lobsterRed};
`
export const SoldOutDetailPageTag = styled(ProductTagDetailPage)`
  border: 1px solid ${colors.darkGrayNoInverter};
  padding: 0 0.5em 0.1em;
  color: ${colors.darkGrayNoInverter};
`
export const PreOrderDetailPageTag = styled(ProductTagDetailPage)`
  border: 1px solid ${colors.darkGrayNoInverter};
  padding: 0 0.5em 0.1em;
  color: ${colors.darkGrayNoInverter};
`
export const NewDetailPageTag = styled(ProductTagDetailPage)`
  border: 1px solid ${colors.darkGrayNoInverter};
  color: ${colors.darkGrayNoInverter};
`

export const SoldOutOverlay = styled.div`
  background-color: white;
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  margin: auto;
  opacity: 0.7;
`

export const SalePrice = styled.span`
  color: ${colors.lobsterRed};
  margin-right: 0.5rem;
`
export const SaleOldPrice = styled.span`
  text-decoration: line-through ${colors.midGray};
  color: ${colors.midGray};
`
