import styled from '@emotion/styled'
import CloseSvg from 'src/static/icons/svg/ll_close.svg'
import { breakpoints } from 'src/utils/styles'

export const PopUpOverlay = styled.div`
  position: fixed;
  z-index: 5;
  background-color: ${props => (props.isOpen ? 'rgba(51, 49, 49, 0.7)' : 'transparent')};
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
`

export const PopUpContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const CloseButtonIcon = styled(CloseSvg)`
  width: 20px;
  height: 20px;
`
export const CloseButton = styled.button`
  all: unset;
  cursor: pointer;
  position: absolute;
  right: 7%;
  top: 5%;
`

export const InvisibleButton = styled.button`
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
`

export const PopUpImage = styled.img`
  border-radius: 5%;
  width: 450px;

  @media (max-width: ${breakpoints.xxl}px) {
    width: 350px;
  }

  @media (max-width: ${breakpoints.l}px) {
    width: 300px;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 280px;
  }

  @media (max-width: ${breakpoints.xs}px) {
    width: 220px;
  }
`
