import styled from '@emotion/styled'
import { breakpoints, colors, fonts, fontSizes } from 'src/utils/styles'
import SearchSvg from 'src/static/icons/svg/ll_search.svg'
import { css } from '@emotion/react'
import SearchSvgWhite from 'src/static/icons/svg/dark-mode/ll_search_white.svg'

const SearchIconStyles = css`
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding-right: 0.5em;
  vertical-align: bottom;
  padding-bottom: 1px;
`

export const SearchForm = styled.form`
  display: initial;
  margin-right: 2em;

  @media (max-width: ${breakpoints.s}px) {
    margin-right: 0;
  }
`

export const Search = styled.input`
  font-family: ${fonts.regular};
  color: ${colors.darkGray};
  cursor: pointer;
  width: 260px;
  box-sizing: border-box;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
  outline: none;
  margin: 0;
  font-size: ${fontSizes.searchBarDesktop};
  ::placeholder {
    color: ${colors.placeholderGray};
    font-family: ${fonts.regular};
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: ${fontSizes.searchBarMobile};
  }
`

export const SearchLabelContainer = styled.div`
  display: unset;
  border: 0;
  border-bottom: 1.5px solid ${colors.darkGray};
  padding: 0 0 0.8em 0;
`

export const SearchIcon = styled(SearchSvg)`
  ${SearchIconStyles};
`

export const SearchIconWhite = styled(SearchSvgWhite)`
  ${SearchIconStyles};
`
