import styled from '@emotion/styled'
import { breakpoints, colors, fonts } from 'src/utils/styles'
import { IS_BLACK_FRIDAY } from 'src/utils/globals'
import SearchSvg from 'src/static/icons/svg/ll_search.svg'
import isPropValid from '@emotion/is-prop-valid'
import { css } from '@emotion/react'
import CloseSvg from 'src/static/icons/svg/ll_close.svg'
import CloseSvgWhite from 'src/static/icons/svg/dark-mode/ll_close_white.svg'

export const StMobileSearch = styled.input`
  font-family: ${fonts.regular};
  cursor: pointer;
  width: 87.5vw;
  background-color: ${IS_BLACK_FRIDAY ? 'black' : 'white'};
  box-sizing: border-box;
  border: 0 none;
  border-radius: 0;
  font-size: 16px;
  outline: none;
  height: 41px;
  margin-top: -7px;
  color: ${IS_BLACK_FRIDAY ? 'white' : 'black'};

  padding: 1em 15px 0.3em 2px;
  border-bottom: 1.5px solid ${colors.darkGray};
  z-index: 999;
  ::placeholder {
    color: ${colors.placeholderGray};
    font-family: ${fonts.regular};
  }

  @media (max-width: ${breakpoints.l}px) {
    width: 87vw;
  }

  @media (max-width: ${breakpoints.m}px) {
    width: 83.5vw;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 90vw;
  }
`

export const StSearchForm = styled.form`
  display: flex;
  position: absolute;
  top: 0.75em;
  right: ${props => (props.isOpen ? '2.9em;' : '4.4em')};
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
  width: ${props => (props.isOpen ? '100%' : '1%')};
`

export const StSearchFormLabel = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 2em;
  background-color: ${IS_BLACK_FRIDAY ? 'black' : 'white'};
  width: 100%;

  @media (max-width: ${breakpoints.xs}px) {
    margin-left: 1em;
  }
`

export const StSearchIcon = styled(SearchSvg, {
  shouldForwardProp: prop => isPropValid(prop),
})`
  cursor: pointer;
  margin-top: 8px;
  width: 20px;
  height: 20px;
  visibility: ${props => (props.isOpen ? 'hidden' : 'visible')};

  @media (max-width: ${breakpoints.l}px) {
    margin-top: 6px;
  }
`

const CloseButtonIconStyles = css`
  width: 20px;
  height: 20px;
  padding: 1em 2.4em 1em 1em;
  margin-right: 0.1em;
  position: absolute;
  background-color: ${IS_BLACK_FRIDAY ? 'black' : 'white'};
  right: -2.5em;
  top: -0.5em;

  @media (max-width: ${breakpoints.s}px) {
    padding: 1em 1.4em 1em 1em;
    right: -1.5em;
  }
`

export const StCloseButtonIcon = styled(CloseSvg)`
  ${CloseButtonIconStyles};
`

export const StCloseButtonIconWhite = styled(CloseSvgWhite)`
  ${CloseButtonIconStyles};
`

export const StCloseButton = styled.span`
  all: unset;
  cursor: pointer;
  z-index: 999;
  background-color: ${IS_BLACK_FRIDAY ? 'black' : 'white'};
`

export const StInnerInputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: ${IS_BLACK_FRIDAY ? 'black' : 'white'};
  padding-left: 3em;
  z-index: 99;
  right: ${props => (props.isOpen ? '-2.5em' : '-100em')};
`
