import { css } from '@emotion/react'
import { breakpoints, colors, fontSizes } from '../../utils/styles'
import styled from '@emotion/styled'
import InstagramSvg from 'src/static/icons/svg/ll_insta_desktop.svg'
import InstagramSvgWhite from 'src/static/icons/svg/dark-mode/ll_insta_desktop_white.svg'
import YouTubeSvg from 'src/static/icons/svg/ll_yt_desktop.svg'
import YouTubeSvgWhite from 'src/static/icons/svg/dark-mode/ll_yt_desktop_white.svg'
import { Link } from 'gatsby-plugin-react-i18next'
import ArrowSvg from 'src/static/icons/svg/ll_footer_arrow_mobile.svg'
import ArrowSvgWhite from 'src/static/icons/svg/dark-mode/ll_footer_arrow_mobile_white.svg'

const LinkStyles = css`
  color: ${colors.darkGray};
  border-bottom: 1px solid transparent;
  text-decoration: none;
  margin: 1em 1.5em;
  width: fit-content;
  padding-bottom: 0.2em;
  white-space: nowrap;

  @media (max-width: ${breakpoints.xxl}px) {
    margin-left: 0.75em;
    margin-right: 0.75em;
  }

  @media (max-width: ${breakpoints.xl}px) {
    margin: 0.5em 0.1em;
    width: unset;
  }
  @media (min-width: ${breakpoints.xl}px) {
    :hover {
      border-bottom: 1px solid ${colors.darkGray};
    }
  }
`

const ArrowIconStyles = css`
  display: none;
  @media (max-width: ${breakpoints.xl}px) {
    display: block;
    height: 11px;
  }
`

const IconStyles = css`
  height: 25px;
  vertical-align: middle;
  margin: 1em 1.5em;
  overflow: visible; !important;


  @media (max-width: ${breakpoints.xl}px) {
    margin: 1em 0.7em;
    height: 20px;
    width: unset;
  }
`

export const StFooter = styled.div`
  background-color: ${colors.lightGrayFooter};
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: ${colors.darkGray};
  max-width: 1920px;
  margin: 0 auto;

  @media (max-width: ${breakpoints.xl}px) {
    padding: 1.15em 1.5em;
  }
`

export const StWrapper = styled.div`
  width: 100%;
  margin: 1rem 4rem;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @media (max-width: ${breakpoints.xl}px) {
    flex-direction: column-reverse;
    width: 100%;
    margin: 0;
  }
`

export const StSecondaryContainer = styled.div`
  flex-basis: 50%;
  display: flex;

  @media (max-width: ${breakpoints.xl}px) {
    display: flex;
    justify-content: left;
    flex-direction: row;
    width: 100%;
  }
`

export const StPrimaryContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;

  @media (max-width: ${breakpoints.xl}px) {
    flex-direction: column;

    width: 100%;
  }
`

export const StLanguageContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin: 1em 1.5em;

  @media (max-width: ${breakpoints.xl}px) {
    margin: 0em 0.1em;
  }
`

export const StSocialMediaContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  a {
    svg {
      g {
        * {
          stroke: none;
        }
      }
    }
  }
`

export const StInstagram = styled(InstagramSvg)`
  ${IconStyles};
`

export const StInstagramWhite = styled(InstagramSvgWhite)`
  ${IconStyles};
`

export const StYouTube = styled(YouTubeSvg)`
  ${IconStyles};
`

export const StYouTubeWhite = styled(YouTubeSvgWhite)`
  ${IconStyles};
`

export const StFooterLinkPrimary = styled(Link)`
  ${LinkStyles};
  font-size: ${fontSizes.footerText};

  @media (max-width: ${breakpoints.xl}px) {
    font-size: ${fontSizes.footerTextMobile};
    border-bottom: 1px solid #3331314d;
    padding-bottom: 1em;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
`

export const StFooterLinkPrimaryNewTarget = styled.a`
  ${LinkStyles};
  font-size: ${fontSizes.footerText};

  @media (max-width: ${breakpoints.xl}px) {
    font-size: ${fontSizes.footerTextMobile};
    border-bottom: 1px solid #3331314d;
    padding-bottom: 1em;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
`

export const StFooterLinkPrimaryNoMarginBot = styled(StFooterLinkPrimary)`
  @media (max-width: ${breakpoints.xl}px) {
    margin-bottom: 0;
  }
`

export const StFooterLinkSecondary = styled(Link)`
  ${LinkStyles};
  font-size: ${fontSizes.footerText};

  @media (max-width: ${breakpoints.xl}px) {
    font-size: ${fontSizes.footerTextSmall};
    margin-right: 3em;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: ${fontSizes.footerTextSuperSmall};
    margin-right: 1em;
  }
`

export const StFooterLinkSecondaryBlankTarget = styled.a`
  ${LinkStyles};
  font-size: ${fontSizes.footerText};

  @media (max-width: ${breakpoints.xl}px) {
    font-size: ${fontSizes.footerTextSmall};
    margin-right: 3em;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: ${fontSizes.footerTextSuperSmall};
    margin-right: 1em;
  }
`

export const StMiscContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  @media (max-width: ${breakpoints.xl}px) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.2em;
  }
`

export const StLanguageLink = styled(Link)`
  ${LinkStyles};
  margin: 0 4px;
  font-size: ${fontSizes.footerText};
`

export const StArrowIcon = styled(ArrowSvg)`
  ${ArrowIconStyles};
`

export const StArrowIconWhite = styled(ArrowSvgWhite)`
  ${ArrowIconStyles};
`
