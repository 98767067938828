import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  PopUpOverlay,
  CloseButtonIcon,
  CloseButton,
  PopUpContainer,
  PopUpImage,
  InvisibleButton,
} from './styles'
import { Cookies } from 'react-cookie'
import { IS_BLACK_FRIDAY } from 'src/utils/globals'
import blackDaysImage from 'src/static/images/black_days.jpg'
import CountdownTimer from 'src/components/Countdown/index'
import { navigate } from 'gatsby'

export default function PopUp() {
  const [isOpen, setIsOpen] = useState(true)

  const data = useStaticQuery(graphql`
    query PopUpQuery {
      file(name: { eq: "popup" }) {
        childMarkdownRemark {
          frontmatter {
            popUpImage
            popUpIsActive
            popUpTitle
            popUpLinkUrl
          }
        }
      }
    }
  `)

  const popUpCookie = new Cookies()

  const setCookie = () => {
    popUpCookie.set('hasSeenPopUp', 'true', { path: '/' })
  }

  const isActive = data.file.childMarkdownRemark.frontmatter.popUpIsActive
  const imageUrl = data.file.childMarkdownRemark.frontmatter.popUpImage
  const linkUrl = data.file.childMarkdownRemark.frontmatter.popUpLinkUrl

  const isCookieActive = popUpCookie.get('hasSeenPopUp')

  let popUp
  if (isActive && isOpen && isCookieActive == null) {
    if (!IS_BLACK_FRIDAY) {
      popUp = (
        <PopUpOverlay isOpen={isOpen}>
          <PopUpContainer>
            <CloseButton
              onClick={() => {
                setIsOpen(!isOpen)
                setCookie()
              }}
            >
              <CloseButtonIcon />
            </CloseButton>
            <InvisibleButton
              rel="noopener noreferrer"
              onClick={() => {
                setIsOpen(!isOpen)
                setCookie()
                navigate(linkUrl)
              }}
            >
              <PopUpImage src={imageUrl} alt="Lobster & Lemonade Special" />
            </InvisibleButton>
          </PopUpContainer>
        </PopUpOverlay>
      )
    } else {
      popUp = (
        <PopUpOverlay isOpen={isOpen}>
          <PopUpContainer>
            <CloseButton
              onClick={() => {
                setIsOpen(!isOpen)
                setCookie()
              }}
            >
              <CloseButtonIcon />
            </CloseButton>
            <PopUpImage src={blackDaysImage} alt="Lobster & Lemonade Black Days" />
            <CountdownTimer targetDate={new Date('2022-11-28T14:00:00')} />
          </PopUpContainer>
        </PopUpOverlay>
      )
    }
  } else {
    popUp = null
  }

  return <>{popUp}</>
}
