import styled from '@emotion/styled'
import { IS_BLACK_FRIDAY } from 'src/utils/globals'

export const StOuterWrapper = styled.div`
  transform: ${props => (props.isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: ${props =>
    props.isOpen
      ? 'transform 0.2s ease-in-out, background-color 0.2s ease'
      : 'transform 0.2s ease-in-out, background-color 0.2s ease'};
  position: fixed;
  z-index: 5;
  background-color: ${props => (props.isOpen ? 'rgba(51, 49, 49, 0.7)' : 'rgba(255,255,255,0)')};
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
`

export const StInnerWrapper = styled.div`
  position: fixed;
  overflow-y: scroll;
  background-color: ${IS_BLACK_FRIDAY ? 'black' : 'white'};
  top: 0;
  right: 0;
  height: 100%;
  max-width: 740px;
  width: 100%;
`
