import styled from '@emotion/styled'
import { breakpoints, colors, fonts, fontSizes } from 'src/utils/styles'

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: ${fonts.regular};
  font-size: ${fontSizes.footerText};

  @media (max-width: ${breakpoints.l}px) {
    font-size: ${fontSizes.footerTextSmall};
  }
`

export const Price = styled.span`
  color: ${colors.midGray};
`

export const RemoveButton = styled.button`
  all: unset;
  cursor: pointer;

  font-family: ${fonts.regular};
  font-size: ${fontSizes.subHeadline};
  color: ${colors.midGray};
  text-decoration: underline;

  @media (max-width: ${breakpoints.l}px) {
    font-size: ${fontSizes.footerTextSmall};
  }
`
export const SelectedSize = styled.span`
  font-family: ${fonts.regular};
  font-size: ${fontSizes.subHeadline};
  color: ${colors.midGray};

  @media (max-width: ${breakpoints.l}px) {
    font-size: ${fontSizes.footerTextSmall};
  }
`

export const QuantityWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

export const QuantityButton = styled.button`
  all: unset;
  cursor: ${props => (props.disabled ? 'unset' : 'pointer')};
  font-family: ${fonts.regular};
  font-size: ${fontSizes.subHeadline};
  color: ${colors.midGray};
  pointer-events: ${props => (props.disabled ? 'none' : 'unset')};

  @media (max-width: ${breakpoints.l}px) {
    font-size: ${fontSizes.footerTextSmall};
  }
`
export const Quantity = styled.span`
  font-size: ${fontSizes.footerText};
  color: ${colors.midGray};

  @media (max-width: ${breakpoints.l}px) {
    font-size: ${fontSizes.footerTextSmall};
  }
`

export const Title = styled.span`
  font-family: ${fonts.extendedBold};
  font-size: ${fontSizes.footerText};
  margin-bottom: 0.5em;

  @media (max-width: ${breakpoints.l}px) {
    font-size: ${fontSizes.footerTextSmall};
    margin-bottom: 0;
  }
`

export const SalePrice = styled.span`
  color: ${colors.lobsterRed};
`

export const SaleOldPrice = styled.span`
  text-decoration: line-through ${colors.midGray};
  color: ${colors.midGray};
`

export const ImageThumbnail = styled.img`
  max-width: 125px;
  max-height: 100px;

  @media (max-width: ${breakpoints.l}px) {
    max-width: 68px;
    max-height: 54px;
  }
`

export const ErrorSpan = styled.span`
  color: ${colors.lobsterRed};
  font-size: ${fontSizes.footerTextSmall};
  white-space: nowrap;
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.35fr 0.25fr;
  justify-content: space-between;
  grid-column-gap: 2rem;
  padding: 1em 0;
  align-items: flex-start;

  @media (max-width: ${breakpoints.l}px) {
    grid-column-gap: 1rem;
    grid-template-columns: 0.5fr 1fr 0.35fr 0.25fr;
  }
`
